@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

/* @tailwind base;
@tailwind components;
@tailwind utilities; */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.custom-navbar {
  background: rgba(44, 22, 86, 1);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.5) !important;
  padding: 10px 100px !important;
}

.nav-item {
  text-align: center;
}


.nav-link {
  color: white !important;
}

.header-login-lets-talk {
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand-logo {
  height: 40px;
}

.start-free-trial-button {
  border-radius: 30px !important;
  padding: 10px 20px !important;
}

.navbar-toggler {
  color: white !important;
  background-color: white !important;
}

.hero-section-items {
  display: flex;
  align-items: center;
}

.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 2rem 4rem 2rem;
  color: white;
  margin-top: 4.4rem;
  background: linear-gradient(180deg, rgba(44, 22, 86, 1) 0%, #7D3560 100%);
}

.hero-text {
  max-width: 50%;
}

.hero-text h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.hero-text p {
  font-size: 1.25rem;
  /* margin-bottom: 2rem; */
}

.hero-text a {
  font-weight: 600;
  text-decoration: none;
  border-bottom: 1px solid #000;
  color: #000;
}

.hero-text .btn {
  font-size: 1rem !important;
  padding: 0.75rem 1.5rem !important;
  margin-bottom: 1rem;
}

.hero-image {
  max-width: 45%;
  margin-bottom: 2rem;
}

.hero-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}




.why-choose-us {
  margin-bottom: 5rem;
}

.alignment-one {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 2rem;
  background-color: #fff;
}


.alignment-one h2, .alignment-two h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.alignment-one-text {
  max-width: 50%;
  padding-left: 3rem;
}

.alignment-two {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  background-color: #fff;
}

.alignment-two .alignment-one-text {
  max-width: 50%;
  padding-left: 0;
}

.why-choose-us-heading {
  padding-top: 5rem;
  text-align: center;
  font-size: 2.8rem;
  margin-bottom: 1rem;
}

.alignment-one-text p {
  font-size: 1.1rem;
}

.alignment-one-text a {
  font-weight: 600;
  text-decoration: none;
  border-bottom: 1px solid #000;
  color: #000;
}

.alignment-one-text .btn {
  font-size: 1rem !important;
  padding: 0.75rem 1.5rem !important;
  margin-bottom: 1rem;
}

.alignment-one-image {
  max-width: 45%;
}

.alignment-one-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}




.security-section {
  background: url('./SecuritySectionBG.png') no-repeat center center;
  background-size: cover;
  color: #fff;
  text-align: center;
  padding: 2rem 1rem !important;
}

.security-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.security-section p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.features {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.feature-card {
  background-color: #fff;
  color: #000;
  border-radius: 8px;
  padding: 1.5rem;
  flex: 1;
  max-width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.feature-card img {
  max-width: 50px;
  margin-bottom: 1rem;
}

.feature-card h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.pricing-plans {
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.toggle-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.toggle-container-border {
  margin: 20px 0;
  padding: 5px 7px;
  border-radius: 40px;
  border: 2px solid #7D3560;
  width: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle-container button {
  background-color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 50%;
  border-radius: 20px;
  /* max-width: fit-content; */
}

.toggle-container button:hover {
  background-color: #f3edf1;
  /* color: white; */
}

.toggle-container button.active {
  background-color: #7D3560;
  color: white;
}

.header-offer {
  background: linear-gradient(90deg, #0A142F 0%, #7D3560 100%);
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
}

.header-offer p {
  font-weight: 800;
}

.plans-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.plan {
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 23rem;
  text-align: left;
}

.plan-header {
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.plan-header h2 {
  margin: 0;
}

.most-popular {
  background: #7D3560;
  border-radius: 20px;
  padding: 2px 12px;
  font-weight: 700;
  height: fit-content;
  display: flex;
  align-items: center;
  color: #fff;
}

.plan-price {
  border-bottom: 1px solid #ddd;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.plan-price h3 {
  margin: 0;
  font-size: 3rem;
}

.plan-price h3 span {
  font-size: 1rem;
  font-weight: normal;
}

.plan-features {
  padding-left: 20px;
  padding-right: 20px;
}

.plan-features p {
  font-size: 0.9rem;
  font-weight: 700;
  margin-bottom: 0;
}

.plan-features ul {
  list-style-type: none;
  padding: 0;
}

.plan-features ul li {
  padding: 5px 0;
  font-size: 0.9rem;
}

.try-free-btn {
  background-color: #7D3560;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  width: 90%;
  margin-top: 10px;
  border-radius: 30px;
  font-weight: 700;
  margin-left: 20px;
  margin-right: 20px !important;
  margin-bottom: 20px;
}

.try-free-btn:hover {
  background-color: #841b5a;
}

.free-trial {
  background-color: rgb(238 250 179);
  text-align: center;
  padding: 6rem 20px;
  font-family: Arial, sans-serif;
}

.free-trial h1 {
  font-size: 36px;
  margin-bottom: 1rem;
}

.free-trial p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.email-signup {
  display: flex;
  justify-content: center;
  margin: 2rem 0rem;
}

.email-signup-border {
  border-radius: 30px;
  padding: 5px;
  background: white;
  border: 1px solid #ccc;
}

.email-signup input {
  padding: 10px 20px;
  font-size: 0.9rem;
  border-radius: 30px 0px 0px 30px;
  background: transparent;
  width: 300px;
  outline: none;
  border: none;
}

.email-signup input::placeholder {
  color: black;
  font-size: 0.9rem;
}

.email-signup button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 0 5px 5px 0;
  background-color: black;
  color: white;
  cursor: pointer;
  border-radius: 30px;
}

.email-signup button:hover {
  background-color: #333;
}

.disclaimer {
  font-size: 0.9rem !important;
}

.footer {
  background: url('./Footer_BG.png') no-repeat center center;
  background-size: cover;
  color: #fff;
  padding: 40px 20px;
  text-align: left;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.footer-column {
  flex: 1;
  min-width: 200px;
  padding: 20px;
}

.footer-logo img {
  width: 100px;
  margin-bottom: 20px;
}

.footer-column h4 {
  font-size: 18px;
  color: #fff;
  margin-bottom: 10px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column a {
  color: #fff;
  text-decoration: none;
}

.footer-column a:hover {
  text-decoration: underline;
}

.footer-column ul li {
  margin-bottom: 10px;
  font-size: 14px;
  color: #b3b3b3;
}

.contact-info p {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.contact-info p i {
  margin-right: 10px;
}

.subscribe-form {
  display: flex;
  flex-direction: column;
}

.subscribe-form input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.subscribe-form button {
  background: linear-gradient(90deg, #0A142F 0%, #5c2747 100%);
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


.footer-bottom {
  width: 100%;
  border-top: 1px solid #b3b3b3;
  padding-top: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-bottom-links {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-bottom-left span,
.footer-bottom-right svg {
  margin: 5px 10px;
  font-size: 14px;
  color: #b3b3b3;
}

.footer-bottom-right svg {
  font-size: 20px;
  cursor: pointer;
}

.footer-bottom-left span svg {
  margin-right: 5px;
}

.footer-bottom-right svg:hover {
  color: white;
}

.copyright-text {
  font-size: 0.9rem;
  color: #b3b3b3;
  margin-top: 20px;
}

.footer-column p {
  font-size: 14px;
  color: #b3b3b3;
}

.footer-column p a {
  color: #b3b3b3;
  text-decoration: none;
}

.footer-column p a:hover {
  text-decoration: underline;
}


.contact-us-section {
  background: url('./ContactUs_BG.png') no-repeat center center;
  background-size: cover;
  padding: 50px 9rem;
  color: white;
  text-align: center;
  margin-top: 3rem;
}

.contact-us-container {
  /* max-width: 800px; */
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  /* background: rgba(0, 0, 0, 0.6); */
  border-radius: 8px;
  /* display: flex; */
  /* padding: 0rem 3rem; */
}

.contact-us-head {
  margin: 3rem 0rem;
}

.contact-us-head h1 {
  font-size: 3rem;
  font-family: "Montserrat", sans-serif;
}

.contact-us-head h3, .contact-us-head p {
  font-family: "Montserrat", sans-serif;
}

.contact-us-left {
  text-align: left;
  padding: 3rem;
}

.contact-us-right {
  /* width: fit-content; */
}

.contact-us-title {
  font-size: 24px;
  color: #F472E7;
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 29.26px;
}

.contact-us-heading {
  font-family: "Montserrat", sans-serif;
  font-size: 70px;
  font-weight: 700;
  line-height: 85.33px;
  text-align: left;
}

.contact-us-subtitle {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.38px;
  text-align: left;
}

.contact-us-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-us-form .form-row {
  display: flex;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;
}

.contact-us-form input,
.contact-us-form textarea {
  width: calc(50% - 10px);
  padding: 15px;
  border: none;
  margin-bottom: 20px;
  background: transparent;
  outline: none;
  color: #b3b3b3;
  font-size: 24px;
  border-bottom: 1px solid rgba(237, 168, 221, 1)
}

.contact-us-form input::placeholder,
.contact-us-form textarea::placeholder {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 29.26px;
  text-align: left;
  color: white;
}

.contact-us-form textarea {
  width: 100%;
}

.submit-button {
  text-align: left;
  width: 100%
}

.submit-button button {
  margin-top: 20px;
  background: rgba(210, 90, 176, 1);
  color: white;
  padding: 20px 40px 20px 40px;
  border-radius: 20px;
  box-shadow: 0px 4px 20px 5px rgba(255, 125, 226, 0.2);
  border: none;
  transition: all 0.3s ease;
}

@media only screen and (min-width: 992px) {
  .custom-navbar {
    width: 100%;
    position: fixed !important;
    top: 0 !important;
  }
}

@media only screen and (max-width: 992px) {
  .hero-section {
    margin-top: 0;
  }

  .hero-text h1 {
    font-size: 2.2rem;
    margin-bottom: 1rem;
  }

  .hero-text h5 {
    font-size: 1.4rem;
  }

  .hero-text p {
    font-size: 1rem;
  }

  .hero-image {
    max-width: 50%;
    margin-left: 2rem;
    margin-bottom: 2rem;
  }

  .alignment-one h2, .alignment-two h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  .alignment-one-text p {
    font-size: 1rem;
  }

  .alignment-one-image {
    max-width: 50%;
  }

  .contact-us-head {
    margin: 1rem 0rem;
  }

  .contact-us-head h1 {
    font-size: 3rem;
    font-family: "Montserrat", sans-serif;
  }

}

@media only screen and (max-width: 768px) {
  .hero-text h1 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  .hero-text h5 {
    font-size: 1.2rem;
  }

  .hero-text p, .hero-text span {
    font-size: 0.9rem;
  }

  .hero-image {
    max-width: 50%;
    margin-left: 2rem;
    margin-bottom: 2rem;
  }

  .alignment-one h2, .alignment-two h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .alignment-one-text p {
    font-size: 0.9rem;
  }

  .alignment-one-text h5 {
    font-size: 1.2rem;
  }

  .alignment-one-image {
    max-width: 50%;
  }

  .contact-us-section {
    padding: 50px 0rem;
    display: flex;
    justify-content: center;
  }

  .contact-us-head {
    margin: 1rem 0rem;
  }

  .footer-bottom-links{
    display: flex;
    justify-content: center;
    gap: 20px;
  }
}

@media only screen and (max-width: 576px) {

  .hero-text {
    max-width: 100%;
  }

  .hero-section-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .hero-text h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .hero-text h5 {
    font-size: 1.5rem;
  }

  .hero-text p, .hero-text span {
    font-size: 1rem;
  }

  .hero-image {
    max-width: 100%;
    margin-left: 0rem;
    margin-bottom: 2rem;
  }

  .alignment-one, .alignment-two {
    flex-direction: column-reverse;
  }

  .alignment-one-text {
    max-width: 100%;
    padding: 3rem;
  }

  .alignment-two .alignment-one-text {
    max-width: 100%;
    padding: 3rem;
  }


  .alignment-one-image {
    max-width: 70%;
  }

  .alignment-one-image img {
    width: 100%;
  }

  .contact-us-section {
    padding: 50px 3rem;
    display: flex;
    justify-content: center;
  }

  .contact-us-head {
    margin: 1rem 0rem;
  }

  .contact-us-head h1 {
    font-size: 2.5rem;
  }


  .contact-us-heading {
    font-size: 4rem;
  }

  .contact-us-subtitle {
    font-size: 1rem;
  }

  .contact-us-form .form-row {
    flex-direction: column;
  }

  .contact-us-form input,
  .contact-us-form textarea {
    width: 100%;
    font-size: 20px;
  }

  .contact-us-form input::placeholder,
  .contact-us-form textarea::placeholder {
    font-size: 20px;
  }

  .custom-navbar {
    padding: 10px 70px !important;
  }

}
@media only screen and (max-width: 500px) {
  .contact-us-section {
    padding: 50px 1rem;
    display: flex;
    justify-content: center;
  }

  .contact-us-head h1 {
    font-size: 2rem;
    font-family: "Montserrat", sans-serif;
  }
  .contact-us-head h3 {
    font-size: 1.3rem;
  }
  .contact-us-heading {
    font-size: 3rem;
  }

  .contact-us-subtitle {
    font-size: 1rem;
  }

  .contact-us-form .form-row {
    display: flex;
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;
    flex-direction: column;
  }

  .contact-us-form input,
  .contact-us-form textarea {
    width: 100%;
    font-size: 20px;
  }

  .contact-us-form input::placeholder,
  .contact-us-form textarea::placeholder {
    font-size: 20px;
  }

  .submit-button {
    text-align: left;
    width: 100%
  }

  .submit-button button {
    padding: 15px 25px 15px 25px;
  }

}

@media only screen and (max-width: 400px){
  .footer-bottom-links .footer-bottom-left{
    display: none !important;
  }
  .custom-navbar {
    padding: 10px 30px !important;
  }
}
@media only screen and (max-width: 360px){
  .contact-us-heading {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}
